export default {
  categoryOrder: {
    'Ant Design': 0,
    全局样式: 1,
    'Global Styles': 1,
    设计模式: 2,
    'Design Patterns': 2,
    '设计模式 - 探索': 3,
    'Design Patterns (Research)': 3,
    Components: 100,
    组件: 100,
  },
  typeOrder: {
    // Component
    Overview: -1,
    UI: 0,
    Runtime: 1,
    Tool: 2,
    Other: 3,

    总览: -1,
    用户界面: 0,
    运行时: 1,
    工具: 2,
    其他: 3,

    // Design
    原则: 1,
    Principles: 1,
    全局规则: 2,
    重型组件: 8,
    ProComponents: 8,
    'Global Rules': 2,
    模板文档: 3,
    'Template Document': 3,
  },
  docVersions: {},
};
