// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import { frontmatter as fm0, toc as t0 } from '/opt/buildhome/repo/.dumi/pages/theme-editor-cn/index.ts?type=frontmatter';
import { frontmatter as fm1, toc as t1 } from '/opt/buildhome/repo/.dumi/pages/theme-editor/index.tsx?type=frontmatter';
import { frontmatter as fm2, toc as t2 } from '/opt/buildhome/repo/.dumi/pages/index-cn/index.ts?type=frontmatter';
import { frontmatter as fm3, toc as t3 } from '/opt/buildhome/repo/.dumi/pages/index/index.tsx?type=frontmatter';
import { frontmatter as fm4, toc as t4 } from '/opt/buildhome/repo/docs/react/use-with-create-react-app.en-US.md?type=frontmatter';
import { demoIndex as dmi4 } from '/opt/buildhome/repo/docs/react/use-with-create-react-app.en-US.md?type=demo-index';
import { frontmatter as fm5, toc as t5 } from '/opt/buildhome/repo/docs/react/use-with-create-react-app.zh-CN.md?type=frontmatter';
import { demoIndex as dmi5 } from '/opt/buildhome/repo/docs/react/use-with-create-react-app.zh-CN.md?type=demo-index';
import { frontmatter as fm6, toc as t6 } from '/opt/buildhome/repo/docs/react/use-with-rsbuild.en-US.md?type=frontmatter';
import { demoIndex as dmi6 } from '/opt/buildhome/repo/docs/react/use-with-rsbuild.en-US.md?type=demo-index';
import { frontmatter as fm7, toc as t7 } from '/opt/buildhome/repo/docs/react/use-with-rsbuild.zh-CN.md?type=frontmatter';
import { demoIndex as dmi7 } from '/opt/buildhome/repo/docs/react/use-with-rsbuild.zh-CN.md?type=demo-index';
import { frontmatter as fm8, toc as t8 } from '/opt/buildhome/repo/docs/react/recommendation.en-US.md?type=frontmatter';
import { demoIndex as dmi8 } from '/opt/buildhome/repo/docs/react/recommendation.en-US.md?type=demo-index';
import { frontmatter as fm9, toc as t9 } from '/opt/buildhome/repo/docs/react/recommendation.zh-CN.md?type=frontmatter';
import { demoIndex as dmi9 } from '/opt/buildhome/repo/docs/react/recommendation.zh-CN.md?type=demo-index';
import { frontmatter as fm10, toc as t10 } from '/opt/buildhome/repo/docs/react/css-variables.en-US.md?type=frontmatter';
import { demoIndex as dmi10 } from '/opt/buildhome/repo/docs/react/css-variables.en-US.md?type=demo-index';
import { frontmatter as fm11, toc as t11 } from '/opt/buildhome/repo/docs/react/css-variables.zh-CN.md?type=frontmatter';
import { demoIndex as dmi11 } from '/opt/buildhome/repo/docs/react/css-variables.zh-CN.md?type=demo-index';
import { frontmatter as fm12, toc as t12 } from '/opt/buildhome/repo/docs/react/use-with-next.en-US.md?type=frontmatter';
import { demoIndex as dmi12 } from '/opt/buildhome/repo/docs/react/use-with-next.en-US.md?type=demo-index';
import { frontmatter as fm13, toc as t13 } from '/opt/buildhome/repo/docs/react/use-with-next.zh-CN.md?type=frontmatter';
import { demoIndex as dmi13 } from '/opt/buildhome/repo/docs/react/use-with-next.zh-CN.md?type=demo-index';
import { frontmatter as fm14, toc as t14 } from '/opt/buildhome/repo/docs/react/use-with-vite.en-US.md?type=frontmatter';
import { demoIndex as dmi14 } from '/opt/buildhome/repo/docs/react/use-with-vite.en-US.md?type=demo-index';
import { frontmatter as fm15, toc as t15 } from '/opt/buildhome/repo/docs/react/use-with-vite.zh-CN.md?type=frontmatter';
import { demoIndex as dmi15 } from '/opt/buildhome/repo/docs/react/use-with-vite.zh-CN.md?type=demo-index';
import { frontmatter as fm16, toc as t16 } from '/opt/buildhome/repo/docs/react/common-props.en-US.md?type=frontmatter';
import { demoIndex as dmi16 } from '/opt/buildhome/repo/docs/react/common-props.en-US.md?type=demo-index';
import { frontmatter as fm17, toc as t17 } from '/opt/buildhome/repo/docs/react/common-props.zh-CN.md?type=frontmatter';
import { demoIndex as dmi17 } from '/opt/buildhome/repo/docs/react/common-props.zh-CN.md?type=demo-index';
import { frontmatter as fm18, toc as t18 } from '/opt/buildhome/repo/docs/react/contributing.en-US.md?type=frontmatter';
import { demoIndex as dmi18 } from '/opt/buildhome/repo/docs/react/contributing.en-US.md?type=demo-index';
import { frontmatter as fm19, toc as t19 } from '/opt/buildhome/repo/docs/react/contributing.zh-CN.md?type=frontmatter';
import { demoIndex as dmi19 } from '/opt/buildhome/repo/docs/react/contributing.zh-CN.md?type=demo-index';
import { frontmatter as fm20, toc as t20 } from '/opt/buildhome/repo/docs/react/use-with-umi.en-US.md?type=frontmatter';
import { demoIndex as dmi20 } from '/opt/buildhome/repo/docs/react/use-with-umi.en-US.md?type=demo-index';
import { frontmatter as fm21, toc as t21 } from '/opt/buildhome/repo/docs/react/use-with-umi.zh-CN.md?type=frontmatter';
import { demoIndex as dmi21 } from '/opt/buildhome/repo/docs/react/use-with-umi.zh-CN.md?type=demo-index';
import { frontmatter as fm22, toc as t22 } from '/opt/buildhome/repo/docs/react/introduce.en-US.md?type=frontmatter';
import { demoIndex as dmi22 } from '/opt/buildhome/repo/docs/react/introduce.en-US.md?type=demo-index';
import { frontmatter as fm23, toc as t23 } from '/opt/buildhome/repo/docs/react/introduce.zh-CN.md?type=frontmatter';
import { demoIndex as dmi23 } from '/opt/buildhome/repo/docs/react/introduce.zh-CN.md?type=demo-index';
import { frontmatter as fm24, toc as t24 } from '/opt/buildhome/repo/docs/react/faq.en-US.md?type=frontmatter';
import { demoIndex as dmi24 } from '/opt/buildhome/repo/docs/react/faq.en-US.md?type=demo-index';
import { frontmatter as fm25, toc as t25 } from '/opt/buildhome/repo/docs/react/faq.zh-CN.md?type=frontmatter';
import { demoIndex as dmi25 } from '/opt/buildhome/repo/docs/react/faq.zh-CN.md?type=demo-index';
import { frontmatter as fm26, toc as t26 } from '/opt/buildhome/repo/components/attachments/index.en-US.md?type=frontmatter';
import { demoIndex as dmi26 } from '/opt/buildhome/repo/components/attachments/index.en-US.md?type=demo-index';
import { frontmatter as fm27, toc as t27 } from '/opt/buildhome/repo/components/attachments/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi27 } from '/opt/buildhome/repo/components/attachments/index.zh-CN.md?type=demo-index';
import { frontmatter as fm28, toc as t28 } from '/opt/buildhome/repo/components/bubble/index.en-US.md?type=frontmatter';
import { demoIndex as dmi28 } from '/opt/buildhome/repo/components/bubble/index.en-US.md?type=demo-index';
import { frontmatter as fm29, toc as t29 } from '/opt/buildhome/repo/components/bubble/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi29 } from '/opt/buildhome/repo/components/bubble/index.zh-CN.md?type=demo-index';
import { frontmatter as fm30, toc as t30 } from '/opt/buildhome/repo/components/conversations/index.en-US.md?type=frontmatter';
import { demoIndex as dmi30 } from '/opt/buildhome/repo/components/conversations/index.en-US.md?type=demo-index';
import { frontmatter as fm31, toc as t31 } from '/opt/buildhome/repo/components/conversations/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi31 } from '/opt/buildhome/repo/components/conversations/index.zh-CN.md?type=demo-index';
import { frontmatter as fm32, toc as t32 } from '/opt/buildhome/repo/components/overview/index.en-US.md?type=frontmatter';
import { demoIndex as dmi32 } from '/opt/buildhome/repo/components/overview/index.en-US.md?type=demo-index';
import { frontmatter as fm33, toc as t33 } from '/opt/buildhome/repo/components/overview/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi33 } from '/opt/buildhome/repo/components/overview/index.zh-CN.md?type=demo-index';
import { frontmatter as fm34, toc as t34 } from '/opt/buildhome/repo/components/prompts/index.en-US.md?type=frontmatter';
import { demoIndex as dmi34 } from '/opt/buildhome/repo/components/prompts/index.en-US.md?type=demo-index';
import { frontmatter as fm35, toc as t35 } from '/opt/buildhome/repo/components/prompts/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi35 } from '/opt/buildhome/repo/components/prompts/index.zh-CN.md?type=demo-index';
import { frontmatter as fm36, toc as t36 } from '/opt/buildhome/repo/components/sender/index.en-US.md?type=frontmatter';
import { demoIndex as dmi36 } from '/opt/buildhome/repo/components/sender/index.en-US.md?type=demo-index';
import { frontmatter as fm37, toc as t37 } from '/opt/buildhome/repo/components/sender/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi37 } from '/opt/buildhome/repo/components/sender/index.zh-CN.md?type=demo-index';
import { frontmatter as fm38, toc as t38 } from '/opt/buildhome/repo/components/suggestion/index.en-US.md?type=frontmatter';
import { demoIndex as dmi38 } from '/opt/buildhome/repo/components/suggestion/index.en-US.md?type=demo-index';
import { frontmatter as fm39, toc as t39 } from '/opt/buildhome/repo/components/suggestion/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi39 } from '/opt/buildhome/repo/components/suggestion/index.zh-CN.md?type=demo-index';
import { frontmatter as fm40, toc as t40 } from '/opt/buildhome/repo/components/thought-chain/index.en-US.md?type=frontmatter';
import { demoIndex as dmi40 } from '/opt/buildhome/repo/components/thought-chain/index.en-US.md?type=demo-index';
import { frontmatter as fm41, toc as t41 } from '/opt/buildhome/repo/components/thought-chain/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi41 } from '/opt/buildhome/repo/components/thought-chain/index.zh-CN.md?type=demo-index';
import { frontmatter as fm42, toc as t42 } from '/opt/buildhome/repo/components/useXAgent/index.en-US.md?type=frontmatter';
import { demoIndex as dmi42 } from '/opt/buildhome/repo/components/useXAgent/index.en-US.md?type=demo-index';
import { frontmatter as fm43, toc as t43 } from '/opt/buildhome/repo/components/useXAgent/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi43 } from '/opt/buildhome/repo/components/useXAgent/index.zh-CN.md?type=demo-index';
import { frontmatter as fm44, toc as t44 } from '/opt/buildhome/repo/components/useXChat/index.en-US.md?type=frontmatter';
import { demoIndex as dmi44 } from '/opt/buildhome/repo/components/useXChat/index.en-US.md?type=demo-index';
import { frontmatter as fm45, toc as t45 } from '/opt/buildhome/repo/components/useXChat/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi45 } from '/opt/buildhome/repo/components/useXChat/index.zh-CN.md?type=demo-index';
import { frontmatter as fm46, toc as t46 } from '/opt/buildhome/repo/components/welcome/index.en-US.md?type=frontmatter';
import { demoIndex as dmi46 } from '/opt/buildhome/repo/components/welcome/index.en-US.md?type=demo-index';
import { frontmatter as fm47, toc as t47 } from '/opt/buildhome/repo/components/welcome/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi47 } from '/opt/buildhome/repo/components/welcome/index.zh-CN.md?type=demo-index';
import { frontmatter as fm48, toc as t48 } from '/opt/buildhome/repo/components/x-provider/index.en-US.md?type=frontmatter';
import { demoIndex as dmi48 } from '/opt/buildhome/repo/components/x-provider/index.en-US.md?type=demo-index';
import { frontmatter as fm49, toc as t49 } from '/opt/buildhome/repo/components/x-provider/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi49 } from '/opt/buildhome/repo/components/x-provider/index.zh-CN.md?type=demo-index';
import { frontmatter as fm50, toc as t50 } from '/opt/buildhome/repo/components/x-request/index.en-US.md?type=frontmatter';
import { demoIndex as dmi50 } from '/opt/buildhome/repo/components/x-request/index.en-US.md?type=demo-index';
import { frontmatter as fm51, toc as t51 } from '/opt/buildhome/repo/components/x-request/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi51 } from '/opt/buildhome/repo/components/x-request/index.zh-CN.md?type=demo-index';
import { frontmatter as fm52, toc as t52 } from '/opt/buildhome/repo/components/x-stream/index.en-US.md?type=frontmatter';
import { demoIndex as dmi52 } from '/opt/buildhome/repo/components/x-stream/index.en-US.md?type=demo-index';
import { frontmatter as fm53, toc as t53 } from '/opt/buildhome/repo/components/x-stream/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi53 } from '/opt/buildhome/repo/components/x-stream/index.zh-CN.md?type=demo-index';
import { frontmatter as fm54, toc as t54 } from '/opt/buildhome/repo/CHANGELOG.zh-CN.md?type=frontmatter';
import { demoIndex as dmi54 } from '/opt/buildhome/repo/CHANGELOG.zh-CN.md?type=demo-index';
import { frontmatter as fm55, toc as t55 } from '/opt/buildhome/repo/CHANGELOG.en-US.md?type=frontmatter';
import { demoIndex as dmi55 } from '/opt/buildhome/repo/CHANGELOG.en-US.md?type=demo-index';

export const filesMeta = {
  'theme-editor-cn/index': {
    frontmatter: fm0,
    toc: t0,
  },
  'theme-editor/index': {
    frontmatter: fm1,
    toc: t1,
  },
  'index-cn/index': {
    frontmatter: fm2,
    toc: t2,
  },
  'index/index': {
    frontmatter: fm3,
    toc: t3,
  },
  'docs/react/use-with-create-react-app.en-US': {
    frontmatter: fm4,
    toc: t4,
    demoIndex: dmi4,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-create-react-app.en-US.md?type=text'),
  },
  'docs/react/use-with-create-react-app.zh-CN': {
    frontmatter: fm5,
    toc: t5,
    demoIndex: dmi5,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-create-react-app.zh-CN.md?type=text'),
  },
  'docs/react/use-with-rsbuild.en-US': {
    frontmatter: fm6,
    toc: t6,
    demoIndex: dmi6,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-rsbuild.en-US.md?type=text'),
  },
  'docs/react/use-with-rsbuild.zh-CN': {
    frontmatter: fm7,
    toc: t7,
    demoIndex: dmi7,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-rsbuild.zh-CN.md?type=text'),
  },
  'docs/react/recommendation.en-US': {
    frontmatter: fm8,
    toc: t8,
    demoIndex: dmi8,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/recommendation.en-US.md?type=text'),
  },
  'docs/react/recommendation.zh-CN': {
    frontmatter: fm9,
    toc: t9,
    demoIndex: dmi9,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/recommendation.zh-CN.md?type=text'),
  },
  'docs/react/css-variables.en-US': {
    frontmatter: fm10,
    toc: t10,
    demoIndex: dmi10,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/css-variables.en-US.md?type=text'),
  },
  'docs/react/css-variables.zh-CN': {
    frontmatter: fm11,
    toc: t11,
    demoIndex: dmi11,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/css-variables.zh-CN.md?type=text'),
  },
  'docs/react/use-with-next.en-US': {
    frontmatter: fm12,
    toc: t12,
    demoIndex: dmi12,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-next.en-US.md?type=text'),
  },
  'docs/react/use-with-next.zh-CN': {
    frontmatter: fm13,
    toc: t13,
    demoIndex: dmi13,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-next.zh-CN.md?type=text'),
  },
  'docs/react/use-with-vite.en-US': {
    frontmatter: fm14,
    toc: t14,
    demoIndex: dmi14,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-vite.en-US.md?type=text'),
  },
  'docs/react/use-with-vite.zh-CN': {
    frontmatter: fm15,
    toc: t15,
    demoIndex: dmi15,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-vite.zh-CN.md?type=text'),
  },
  'docs/react/common-props.en-US': {
    frontmatter: fm16,
    toc: t16,
    demoIndex: dmi16,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/common-props.en-US.md?type=text'),
  },
  'docs/react/common-props.zh-CN': {
    frontmatter: fm17,
    toc: t17,
    demoIndex: dmi17,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/common-props.zh-CN.md?type=text'),
  },
  'docs/react/contributing.en-US': {
    frontmatter: fm18,
    toc: t18,
    demoIndex: dmi18,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/contributing.en-US.md?type=text'),
  },
  'docs/react/contributing.zh-CN': {
    frontmatter: fm19,
    toc: t19,
    demoIndex: dmi19,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/contributing.zh-CN.md?type=text'),
  },
  'docs/react/use-with-umi.en-US': {
    frontmatter: fm20,
    toc: t20,
    demoIndex: dmi20,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-umi.en-US.md?type=text'),
  },
  'docs/react/use-with-umi.zh-CN': {
    frontmatter: fm21,
    toc: t21,
    demoIndex: dmi21,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-umi.zh-CN.md?type=text'),
  },
  'docs/react/introduce.en-US': {
    frontmatter: fm22,
    toc: t22,
    demoIndex: dmi22,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/introduce.en-US.md?type=text'),
  },
  'docs/react/introduce.zh-CN': {
    frontmatter: fm23,
    toc: t23,
    demoIndex: dmi23,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/introduce.zh-CN.md?type=text'),
  },
  'docs/react/faq.en-US': {
    frontmatter: fm24,
    toc: t24,
    demoIndex: dmi24,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/faq.en-US.md?type=text'),
  },
  'docs/react/faq.zh-CN': {
    frontmatter: fm25,
    toc: t25,
    demoIndex: dmi25,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/faq.zh-CN.md?type=text'),
  },
  'components/attachments/index.en-US': {
    frontmatter: fm26,
    toc: t26,
    demoIndex: dmi26,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/attachments/index.en-US.md?type=text'),
  },
  'components/attachments/index.zh-CN': {
    frontmatter: fm27,
    toc: t27,
    demoIndex: dmi27,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/attachments/index.zh-CN.md?type=text'),
  },
  'components/bubble/index.en-US': {
    frontmatter: fm28,
    toc: t28,
    demoIndex: dmi28,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/bubble/index.en-US.md?type=text'),
  },
  'components/bubble/index.zh-CN': {
    frontmatter: fm29,
    toc: t29,
    demoIndex: dmi29,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/bubble/index.zh-CN.md?type=text'),
  },
  'components/conversations/index.en-US': {
    frontmatter: fm30,
    toc: t30,
    demoIndex: dmi30,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/conversations/index.en-US.md?type=text'),
  },
  'components/conversations/index.zh-CN': {
    frontmatter: fm31,
    toc: t31,
    demoIndex: dmi31,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/conversations/index.zh-CN.md?type=text'),
  },
  'components/overview/index.en-US': {
    frontmatter: fm32,
    toc: t32,
    demoIndex: dmi32,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/overview/index.en-US.md?type=text'),
  },
  'components/overview/index.zh-CN': {
    frontmatter: fm33,
    toc: t33,
    demoIndex: dmi33,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/overview/index.zh-CN.md?type=text'),
  },
  'components/prompts/index.en-US': {
    frontmatter: fm34,
    toc: t34,
    demoIndex: dmi34,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/prompts/index.en-US.md?type=text'),
  },
  'components/prompts/index.zh-CN': {
    frontmatter: fm35,
    toc: t35,
    demoIndex: dmi35,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/prompts/index.zh-CN.md?type=text'),
  },
  'components/sender/index.en-US': {
    frontmatter: fm36,
    toc: t36,
    demoIndex: dmi36,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/sender/index.en-US.md?type=text'),
  },
  'components/sender/index.zh-CN': {
    frontmatter: fm37,
    toc: t37,
    demoIndex: dmi37,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/sender/index.zh-CN.md?type=text'),
  },
  'components/suggestion/index.en-US': {
    frontmatter: fm38,
    toc: t38,
    demoIndex: dmi38,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/suggestion/index.en-US.md?type=text'),
  },
  'components/suggestion/index.zh-CN': {
    frontmatter: fm39,
    toc: t39,
    demoIndex: dmi39,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/suggestion/index.zh-CN.md?type=text'),
  },
  'components/thought-chain/index.en-US': {
    frontmatter: fm40,
    toc: t40,
    demoIndex: dmi40,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/thought-chain/index.en-US.md?type=text'),
  },
  'components/thought-chain/index.zh-CN': {
    frontmatter: fm41,
    toc: t41,
    demoIndex: dmi41,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/thought-chain/index.zh-CN.md?type=text'),
  },
  'components/useXAgent/index.en-US': {
    frontmatter: fm42,
    toc: t42,
    demoIndex: dmi42,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/useXAgent/index.en-US.md?type=text'),
  },
  'components/useXAgent/index.zh-CN': {
    frontmatter: fm43,
    toc: t43,
    demoIndex: dmi43,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/useXAgent/index.zh-CN.md?type=text'),
  },
  'components/useXChat/index.en-US': {
    frontmatter: fm44,
    toc: t44,
    demoIndex: dmi44,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/useXChat/index.en-US.md?type=text'),
  },
  'components/useXChat/index.zh-CN': {
    frontmatter: fm45,
    toc: t45,
    demoIndex: dmi45,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/useXChat/index.zh-CN.md?type=text'),
  },
  'components/welcome/index.en-US': {
    frontmatter: fm46,
    toc: t46,
    demoIndex: dmi46,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/welcome/index.en-US.md?type=text'),
  },
  'components/welcome/index.zh-CN': {
    frontmatter: fm47,
    toc: t47,
    demoIndex: dmi47,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/welcome/index.zh-CN.md?type=text'),
  },
  'components/x-provider/index.en-US': {
    frontmatter: fm48,
    toc: t48,
    demoIndex: dmi48,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/x-provider/index.en-US.md?type=text'),
  },
  'components/x-provider/index.zh-CN': {
    frontmatter: fm49,
    toc: t49,
    demoIndex: dmi49,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/x-provider/index.zh-CN.md?type=text'),
  },
  'components/x-request/index.en-US': {
    frontmatter: fm50,
    toc: t50,
    demoIndex: dmi50,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/x-request/index.en-US.md?type=text'),
  },
  'components/x-request/index.zh-CN': {
    frontmatter: fm51,
    toc: t51,
    demoIndex: dmi51,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/x-request/index.zh-CN.md?type=text'),
  },
  'components/x-stream/index.en-US': {
    frontmatter: fm52,
    toc: t52,
    demoIndex: dmi52,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/x-stream/index.en-US.md?type=text'),
  },
  'components/x-stream/index.zh-CN': {
    frontmatter: fm53,
    toc: t53,
    demoIndex: dmi53,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/x-stream/index.zh-CN.md?type=text'),
  },
  'changelog-cn': {
    frontmatter: fm54,
    toc: t54,
    demoIndex: dmi54,
    textGetter: () => import(/* webpackChunkName: "meta__CHANGELOG.zh-CN.md__zh-CN" */'/opt/buildhome/repo/CHANGELOG.zh-CN.md?type=text'),
  },
  'changelog': {
    frontmatter: fm55,
    toc: t55,
    demoIndex: dmi55,
    textGetter: () => import(/* webpackChunkName: "meta__CHANGELOG.en-US.md__en-US" */'/opt/buildhome/repo/CHANGELOG.en-US.md?type=text'),
  },
}

export { tabs as tabsMeta } from './tabs';
